import { Routes } from '@angular/router';
import { HomeComponent } from './home';
import { ApiComponent } from './api';

//import { angularProfileCard } from '../../components/main-profile/index';
import { NoContentComponent } from './no-content';

import { DataResolver } from './app.resolver';

export const ROUTES: Routes = [
  { path: '',      component: HomeComponent },
  { path: 'api',   component: ApiComponent },
  { path: 'wilayah', loadChildren: './wilayah#WilayahModule' },
  { path: '**',    component: NoContentComponent },
];
